<template>
  <v-card min-height="400px" color="#273142">
    <v-card-title class="card-header">
    <h3>Transactions</h3>
    <v-spacer> </v-spacer>
    <div class="">
      <v-select
      :items="transactionTypes"
      background-color="transparent"
      placeholder="Filter Transaction"
      outlined
      class="pa-0 mt-n7"
      style="height: 30px;"
      v-model="transactionType"
      ></v-select>
    </div>
  </v-card-title>

  <v-card-text class="pa-0" flat>
    <v-data-table
      :headers="headers"
      :items="transactions"
      :items-per-page="5"
      item-text="text"
      item-value="value"
      class="transparent"
      :color="ant"
      item-key="id"
      show-expand
    > 

      <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <v-row>
          <v-col cols=8>
            <v-simple-table class="transparent">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Status</td>
                    <td>
                      <v-badge
                        inline
                        icon="mdi-marker-check"
                        :color="getTransactionColor(item.status)"
                        left
                        tile
                      >
                      <span class="ml-2 essay-green" :class="{'red--text' : item.status === 'failed'}"> {{ item.status }} </span>
                      </v-badge>
                    </td>
                  </tr>
                  <tr>
                    <td>Date</td>
                    <td> {{ formatDateMonth(item.created_at) }}</td>
                  </tr>
                  <tr>
                    <td>ID</td>
                    <td> {{ item.id }} </td>
                  </tr>
                  <tr>
                    <td>Order</td>
                    <td class="link" v-if="item.order_id" @click="getRoute(item.order_id)"> Order {{ item.order_id }}</td>
                    <td class="" v-else> Order {{ '__' }}</td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td> {{ item.description }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col>
           <div class="d-flex justify-end">
             <div class="d-flex flex-column mr-15 mt-5">
               <div>
                 <span> Amount </span>
               </div>
               <h1 class="essay-green" :class="{'red--text' : item.status === 'failed'}"> {{ getSignedAmount(item) }}</h1>
               <div>
                <!-- <span class="text--secondary">Balance:</span> <span class="ml-2"><strong>{{ item.balance }} </strong></span> -->
               </div>
             </div>
           </div>
          </v-col>
        </v-row> 
      </td>
      </template>
      <template v-slot:item.created_at="{ item }">
        <span>{{ formatDateMonth(item.created_at) }}</span>
      </template>
      <template v-slot:item.amount="{ item }">
        <span>{{ getSignedAmount(item) }}</span>
      </template>
      <template v-slot:item.order_id="{ item }">
        <span>{{ item.order_id ? item.order_id : '__' }}</span>
      </template>
    </v-data-table>
  </v-card-text>
</v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import colorMxn from '@/mixins/colorMxn';
import timeZoneMxn from '@/mixins/timezone_mixin';

export default {
  name: 'transactionList',
  mixins: [colorMxn, timeZoneMxn],
  data() {
    return{
       headers: [
        { text: "Date", value: "created_at" },
        { text: "Type", value: "description" },
        { text: "Amount", value: "amount" },
        { text: "Order Id", value: "order_id" },
        { text: '', value: 'data-table-expand'}
      ],
      transactions: [],
      transactionTypes: [
        {
          text: 'All',
          value: ['debit', 'deposit', 'credit'],
        },
        {
          text: 'Deposit',
          value: ['deposit'],
        },
        {
          text: 'Refund',
          value: ['credit'],
        },
      ],
      transactionType: ''      
    }
  },
  computed: {
    ...mapGetters(['getSession']),
  },
  watch: {
    transactionType() {
      this.getTransactions();
    }
  },
  mounted() {
    this.transactionType = this.getSession.user_type === 1 ? ['debit', 'deposit', 'credit'] : ['withdraw', 'credit', 'debit'];
    this.getTransactions();
  },
  methods: {
    ...mapActions(['performGetActions']),
    async getTransactions() {
      const payload = {
        page: 1,
        limit: 20,
        transaction_type: this.transactionType,

      };

      if (this.getSession.user_type === 1) {
        payload.customer_id =this.getSession.customer.id;
        // payload.transaction_type = ['debit', 'deposit', 'credit'];
      }

      if (this.getSession.user_type === 2) {
        payload.customer_id =this.getSession.writer.id;
        // payload.transaction_type = ['withdraw', 'credit', 'debit'];
      }

      const fullPayload = {
        params: payload,
        endpoint: '/transaction/show'
      }

      const response = await this.performGetActions(fullPayload);
      this.transactions = response.data.data;
    },
    getSignedAmount(item) {
      let sign = ''
      switch (item.transaction_type) {
        case 'deposit':
          sign = '';
          break;
        case 'debit':
          sign = '-';
          break;
        case 'credit':
          sign = '';
          break;
        case 'withdraw':
          sign = '-';
          break;
        default:
          break;
      }

      const result = `${sign}$${item.amount}`;
      return result;
    },
    getRoute(id) {
      const url = this.getSession.user_type === 1 ? `/dashboard/order/${id}` : `/orderDetail/${id}/bid`
      this.$router.push(url);
    }
  }
}
</script>

<style>
.essay-green {
  color:#038c43;
}

tbody tr {
  border-bottom: none;
}

.link {
  cursor: pointer;
  color: #0062DB;
  text-decoration: underline;
}
</style>